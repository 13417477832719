import {PaymentTypeType} from '../datastore/features/payment/storeSinglePayment';
import {AccountType, BeneficiaryAccount} from './BeneficiaryTypes';

export const EXPIRATION_DELAY_TO_FILTER_CANCELLED_PRE_INSTRUCTIONS_IN_DAYS: number = 3;

export type Country = {
    value: string;
    label: string;
};

export type ServerDate = {
    serverDate: string;
};

export type IbanCountryRules = {
    countryCode: string;
    ibanOnly: boolean;
    patterns: IbanPattern[];
};

export type IbanPattern = {
    startWith: string;
    length: number;
};

export type CurrencyResponse = {
    currencies: string[];
};

export type PaymentTypesResponse = {
    types: PaymentTypeType[];
};

export type FeesResponse = {
    types: FeesTypeEnum[];
};

export interface CountryCodeReporting {
    messagesByCountry: { [key: string]: any };
    numberOfDigitsByCountry: { [key: string]: any };
}

export type Amount = {
    value: number | null;
    currency: string;
};

export type AmountDAL = {
    nb: number | null;
    currencyRef: string;
};

export interface Account {
    accountReference: string;
    label: string;
    amount: Amount;
}

export interface AccountsRoot {
    root: string;
    accounts: Account[];
}

export interface CreditableRoots {
    beneficiaryPaymentAllowed: boolean;
    rootAccounts: AccountsRoot[];
}

export type PreInstructionOrder = {
    orderBy: string;
    ordering: 'ASCENDING' | 'DESCENDING';
};

export type PaymentHistoryOrder = {
    orderBy: string;
    ordering: 'ASCENDING' | 'DESCENDING';
};

export type PreInstructionListRequest = {
    page: number;
    limit: number;
    order?: PreInstructionOrder;
    filter?: PreInstructionListFilter;
    contractReference: string;
    primaryContracts: Array<string>;
    excludeValidated: boolean;
    excludeCancelled: boolean;
    couldSign: boolean;
    expirationDelayInDays: number;
};

export type PaymentHistoryListRequest = {
    page: number;
    limit: number;
    contractReference: string;
    order: PaymentHistoryOrder;
    filter?: PaymentHistoryListFilter;
    primaryContracts: string[];
    withSalary?: boolean | null;
};

export type TransactionsBulkRequest = {
    uuid: string;
    wbbContractId: string;
};

export type TransactionsBulkResponse = {
    amount: Amount;
    comment: string;
    endToEndReference: string;
    beneficiary: BeneficiaryAccount;
    paymentTypes: PaymentTypeType[];
};


export type StandingOrderLightHistoryListRequest = {
    contractReference: string;
    primaryContract: string;
    filter?: StandingOrderHistoryListFilter
};

export type StandingOrderRequest = {
    contractReference: string;
    primaryContract: string;
    standingOrderReference: string;
};

export type PreInstructionRequest = {
    uuid: string;
    contractReference: string;
};

export type PaymentHistoryDetailsRequest = {
    uuid: string;
    contractReference: string;
};

export type CollectiveDetailsRequest = {
    contractReference: string;
    primaryContract: string;
    messageId: string;
};

export type SignPreInstructionRequest = {
    uuid: string;
    contractReference: string;
    signatureRequest: { challengeId: string; answer: string };
};

export type SignStandingOrderPreInstructionRequest = {
    primaryContract: string;
    instructionUuid: string;
    contractReference: string;
    signatureRequest: { challengeId: string; answer: string };
};

export type PreInstructionListFilter = {
    accountFrom: string | null;
    accountBeneficiary: string | null;
    amountMin: number | null;
    amountMax: number | null;
    dateExecutionFrom: string | null;
    dateExecutionTo: string | null;
};

export type PaymentHistoryListFilter = {
    accountFrom: string | null;
    accountBeneficiary: string | null;
    amountMin: number | null;
    amountMax: number | null;
    dateExecutionFrom: string | null;
    dateExecutionTo: string | null;
    overallStatus: string | null;
};

export type StandingOrderHistoryListFilter = {
    accountFrom: string | null;
    dateExecutionFrom: string | null;
    dateExecutionTo: string | null;
};

export enum FeesTypeEnum {
    REGULAR = 'REGULAR',
    INITIATOR_FEES = 'INITIATOR_FEES',
    BENEFICIARY_FEES = 'BENEFICIARY_FEES',
    NO_FEES = 'NO_FEES',
    SHARED = 'SHARED',
    DEBTOR = 'DEBTOR',
    CREDITOR = 'CREDITOR',
    NONE = 'NONE',
    UNKNOWN = 'UNKNOWN'
}

export enum SignatureStatusEnum {
    INIT = 'INIT',
    AWSS = 'AWSS',
    TICR = 'TICR',
    VLDT = 'VLDT',
    CANC = 'CANC',
    TCER = 'TCER',
    INJC = 'INJC',
    REJT = 'REJT'
}

export enum BulkStatus {
    EXECUTED = 'EXECUTED',
    CANCELLED = 'CANCELLED',
    REJECTED= 'REJECTED',
    PARTIALLY_EXECUTED= 'PARTIALLY_EXECUTED',
    IN_PROCESS= 'IN_PROCESS',
}

export type Debitor = {
    debitorName: string;
    debitorIban: string;
};

export type PreInstructionResponse = {
    reference:string;
    uuid: string;
    transferType: string;
    executionDate: string;
    amount: Amount;
    accountFrom: string;
    accountFromAmount: Amount;
    accountFromName: string;
    beneficiaryAccount: string;
    beneficiaryAmount: Amount;
    beneficiaryName: string;
    beneficiaryAccountFormat: AccountType;
    beneficiaryBankCode: string | null;
    beneficiaryBankCountry: string | null;
    beneficiaryBankName: string | null;
    beneficiaryBankCity: string | null;
    beneficiaryForeignNationalReference: string | null;
    userActions: UserActions;
    validationDate1?: string;
    validationUser1?: string;
    validationDate2?: string;
    validationUser2?: string;
    creationDate: string;
    creationUser: string;
    communication?: string;
    endToEndReference?: string;
    regulatoryReportingReason?: string;
    debitAdvice?: boolean;
    fees: FeesTypeEnum;
    urgent?: boolean;
    rtgs?: boolean;
    salary?: boolean;
    periodicity?: string;
    transferPreValidationResponse: TransferPreValidationResponse;
    status: SignatureStatusEnum;
    fileReference?: string;
    messageId?: string;
    paymentId?: string;
    numberOfTransactions?: string;
    batchBookingGlobal: boolean | false;
    typePaymentCode?: string;
};

export type StandingOrderPreInstructionResponse = {
    uuid: string;
    primaryContract: string;
    reference: string;
    transferType: string;
    executionDate: string;
    lastExecutionDate?: string;
    firstAuthorizedExecutionDate?: string,
    amount: Amount;
    accountFrom: string;
    accountFromAmount: Amount;
    accountFromName: string;
    beneficiaryAccount: string;
    beneficiaryAmount: Amount;
    beneficiaryName: string;
    beneficiaryCity: string;
    beneficiaryAddress: string;
    beneficiaryAccountFormat: AccountType;
    beneficiaryPrimaryContract: string;
    beneficiaryBankCode: string | null;
    beneficiaryBankCountry: string | null;
    beneficiaryBankName: string | null;
    beneficiaryBankCity: string | null;
    beneficiaryForeignNationalReference: string | null;
    saveBeneficiary: boolean;
    userActions: UserActions;
    validationDate1?: string;
    validationUser1?: string;
    creationDate: string;
    creationUser: string;
    communication?: string;
    endToEndReference?: string;
    regulatoryReportingReason?: string;
    debitAdvice?: boolean;
    fees: FeesTypeEnum;
    urgent?: boolean;
    rtgs?: boolean;
    salary?: boolean;
    standingOrder: boolean;
    periodicity: StandingOrderPeriod;
    executionProof: boolean;
    specificCode: string;
    status: string;
    transferPreValidationResponse: TransferPreValidationResponse;
};

export type PaymentHistoryAccountOwner = {
    name: string;
    address: string;
};

export type PaymentHistoryAccount = {
    reference: string;
    referenceType: string;
    bankLabel: string;
    bglContract: PaymentHistoryContract;
    owner: PaymentHistoryAccountOwner;
};

export type PaymentHistoryCharacteristics = {
    salary: boolean;
};

export type PaymentHistoryCharacteristicsDetails = {
    instant: boolean;
    salary: boolean;
    costType: string;
    urgent: boolean;
    realTimeGrossSettlement: boolean;
    isPaymentProofExisting: boolean;
};

export type PaymentHistoryResponse = {
    id: string;
    executionDate: string;
    debitAccount: PaymentHistoryAccount;
    creditAccount: PaymentHistoryAccount;
    transferAmount: AmountDAL;
    overallStatus: string;
    typeEnum: string;
    characteristics: PaymentHistoryCharacteristics;
    numberOfTrx: number;
};

export type CollectiveHistoryResponse = {
    messageId: string;
    paymentId: string;
    executionDate: string;
    debitAccount: PaymentHistoryAccount;
    transferAmount: AmountDAL;
    overallStatus: string;
    typeEnum: string;
    characteristics: PaymentHistoryCharacteristics;
    numberOfTrx: number;
};

export type PaymentHistoryFee = {
    finalAmountInEuro: AmountDAL;
    finalAmount: AmountDAL;
    typeEnum: string;
    feeChargeType: string;
};

export enum PaymentHistoryDetailsContributorTypeEnum {
    ENCODER = 'ENCODER',
    OTHER = 'OTHER',
    SIGNATORY1 = 'SIGNATORY1',
    SIGNATORY2 = 'SIGNATORY2',
    UNKNOWN_ENUM_VALUE = 'UNKNOWN_ENUM_VALUE',
    SIGNATORY_ONE = 'SIGNATORY_ONE',
}

export type PaymentHistoryDetailsContributorParty = {
    label: string;
};

export type PaymentHistoryDetailsContributor = {
    party: PaymentHistoryDetailsContributorParty;
    typeEnum: PaymentHistoryDetailsContributorTypeEnum;
};

export type CollectiveDetailsReponse = {
    contributor: PaymentHistoryDetailsContributor[];
    collectiveLight: CollectiveHistoryResponse;
    transactions: TransferFromCollective[];
    canBeCancel: boolean;
};

export type PaymentHistoryDetailsResponse = {
    id: string;
    externalReference: string;
    accountingDate: string;
    executionDate: string;
    editionDate: string;
    communication: string;
    regulatoryReportingCode: string;
    debitAccount: PaymentHistoryAccount;
    creditAccount: PaymentHistoryAccount;
    transferAmount: AmountDAL;
    transferAmountInContractCurrency: AmountDAL;
    fees: PaymentHistoryFee[];
    overallStatus: string;
    typeEnum: string;
    customerEndToEndMessage: string;
    characteristics: PaymentHistoryCharacteristicsDetails;
    contributor: PaymentHistoryDetailsContributor[];
};

export type StandingOrderLight = {
    reference: string;
    initiatorAccount: string;
    poc: string;
    initiatorName: string;
    beneficiaryAccount: string;
    targetPoc: string;
    beneficiaryName: string;
    amount: number;
    currency: string;
    status: 'CREATED' | 'CANCELLED';
    canBeDeleted: boolean;
    nextExecutionDate: string;
    periodicity: StandingOrderPeriod;
};

export type PaymentHistoryContract = {
    cashPositions: IntraDayPosition;
    productOfferTarget: ProductOfferTarget;
};

export type IntraDayPosition = {
    intraDayPosition: AmountPosition;
};

export type AmountPosition = {
    amount: AmountDAL;
};

export type ProductOfferTarget = {
    productRef: string;
    label: string;
};

export type PreInstructionListResponse = {
    data: PreInstructionResponse[];
    totalNumberOfPage: number;
};

export type StandingOrderPreInstructionListResponse = {
    data: StandingOrderPreInstructionResponse[];
    totalNumberOfPage: number;
};

export type PreInstructionCountResponse = {
    count: number;
};

export type PaymentHistoryListResponse = {
    data: PaymentHistoryResponse[];
    totalNumberOfPage: number;
};

export type TransferFromCollective = {
    amount: Amount;
    amountInitial: Amount;
    beneficiary: BeneficiaryAccount;
    externalReference: string;
    comment: string;
    endToEndReference: string;
    paymentTypes: string;
    overallStatus: string;
    paymentProof: boolean;
    beneficiaireCpt: string;
    beneficiaireNm: string;
};

export type CollectiveHistoryListResponse = {
    data: CollectiveHistoryResponse[];
    totalNumberOfPage: number;
};

export type StandingOrderLightListResponse = {
    standingOrderLightList: StandingOrderLight[];
};

export type StandingOrderDetailsResponse = {
    reference: string;
    initiatorAccount: string;
    initiatorName: string;
    poc: string;
    targetPoc: string | null;
    targetAccount: string;
    targetName: string;
    amount: number;
    currency: string;
    standingOrderDeleted: boolean;
    updatable: boolean;
    readOnlyContract: boolean;
    initiatorCommunication: string;
    lastExecutionDate: string | null;
    nextExecutionDate: string | null;
    firstExecutionDate: string | null;
    periodicity: StandingOrderPeriod;
    bankTypeCode: string;
    targetBankCountryCode : string;
    targetCountryCode: string;
    targetBankName: string;
    targetBankAddress: string;
    ibanFormat: boolean;
    bic: string;
    bankCode: boolean;
    initiatorNotification: boolean;
    inputChannel: string
    encodingUser: string | null;
    encodingDate: string;
    signDate1: string | null;
    signDate2: string | null;
    signUser1: string | null;
    signUser2 :string | null;
    transferRequestFees: FeesTypeEnum;
};

export type UserActions = {
    couldCancel: boolean;
    couldSign: boolean;
    nextSignStatus: SignatureStatusEnum;
};

export enum EstimatedFeeType {
    OPERATION_TRANSFER = 'OPERATION_TRANSFER',
    URGENT_TRANSFER = 'URGENT_TRANSFER',
    CHANGE_COMMISSION = 'CHANGE_COMMISSION',
    CORRESPONDENCE_FEES = 'CORRESPONDENCE_FEES',
    CORRESPONDENT_FEE = 'CORRESPONDENCE_FEES',
    FRS_EDP_BIC_IBA = 'FRS_EDP_BIC_IBA'
}

export enum StandingOrderPeriod {
    MENSUEL = 'MENSUEL',
    BIMESTRIEL = 'BIMESTRIEL',
    TRIMESTRIEL = 'TRIMESTRIEL',
    SEMESTRIEL = 'SEMESTRIEL',
    ANNUEL = 'ANNUEL'
}

export type EstimatedTransferFees = {
    feeType: EstimatedFeeType;
    effectiveAmount: Amount;
    effectiveFeesAmountInOwnerCurrency: Amount;
};

export type TransferEstimationFees = {
    initiatorFees: EstimatedTransferFees[];
    beneficiaryFees: EstimatedTransferFees[];
    totalInitiatorEffectiveFees: Amount;
    totalInitiatorEffectiveFeesInitiatorAccountCurrency: Amount;
    totalBeneficiaryEffectiveFees: Amount;
    totalBeneficiaryEffectiveFeesInCurrencyTransferedToBeneficiary: Amount;
    debitAmount: Amount;
    beneficiaryTransferAmount: Amount;
    paymentAmount: Amount;
};

export enum TransferPreValidationMessageType {
    PRE_INSTRUCTION_NEEDED = 'PRE_INSTRUCTION_NEEDED',
    MAX_CONTRACT_AMOUNT_EXCEEDED = 'MAX_CONTRACT_AMOUNT_EXCEEDED',
    POSSIBLE_DUPLICATE = 'POSSIBLE_DUPLICATE'
}

export type TransferPreValidationMessage = {
    transferPreValidationMessageType: TransferPreValidationMessageType;
};

export type TransferPreValidationResponse = {
    transferPreValidationWarnings: TransferPreValidationMessage[];
    transferPreValidationErrors: TransferPreValidationMessage[];
};

export enum TransferWarning {
    NO_WARNING = 'NO_WARNING',
    FAILED_TO_SAVE_BENEFICIARY = 'FAILED_TO_SAVE_BENEFICIARY',
    FAILED_TO_CANCELLED_TRANSFER = 'FAILED_TO_CANCELLED_TRANSFER'
}

export type TransferCancelResponse = {
    referenceOperation: string;
    preInstruction: boolean;
    transferWarnings: TransferWarning[];
};

export type CancelResultResponse = {
    paymentId: string;
};

export type CancelTransferRequest = {
    contractReference: string;
    transferExternalReference: string;
    primaryContract: string;
};

export type CancelCollectiveRequest = {
    contractReference: string;
    paymentId: string;
    primaryContract: string;
};

export type GenericFee = {
  amount: number;
  currency: string;
  label: string;
};
