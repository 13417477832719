import {formatISO, isValid, parseISO} from 'date-fns';
import {TFunction} from 'i18next';
import {extractBIC, extractIBAN, isValidBIC, isValidIBAN} from 'ibantools';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Validator from 'validatorjs';
import {AccountType, BankCodeType} from '../redux/@types/BeneficiaryTypes';

export const alphaNumericWithAccents = 'regex:/^[A-Za-z0-9À-ÖØ-öø-ÿ]+$/';
export const alphaNumericWithAccentsAndSomeSpecials = "regex:/^[A-Za-z0-9À-ÖØ-öø-ÿ- _.,@&/'+():?-]+$/";
export const alphaNumericWithSomeSpecials = "regex:/^[A-Za-z0-9 /?:().,'+-]+$/";


const isValidDate = (date: any) => typeof date === 'string' && isValid(parseISO(date));
const validPhoneNumberCheck = RegExp(/^\+?\d{5,20}$/);
const validPhonePrefixCheck = RegExp(/^\+?(0{2})?\d{2,3}$/);

const validNomenclatureGB = RegExp(/^\d{6}$/);
const validNomenclatureUS = RegExp(/^\d{9}$/);
const NINETY_DAYS = 90;

export const maxDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + NINETY_DAYS);
    return date;
};

const registerCustomValidators = (t: TFunction<['commons']>) => {


    Validator.register(
        'phonePrefix',
        function (value, requirement, attribute) {
            return validPhonePrefixCheck.exec(String(value)) !== null;
        },
        t('commons:errors.phone_number')
    );

    Validator.register(
        'phoneNumber',
        function (value, requirement, attribute) {
            return validPhoneNumberCheck.exec(String(value)) !== null;
        },
        t('commons:errors.phone_number')
    );

    Validator.register(
        'iban',
        function (value, requirement, attribute) {
            return isValidIBAN(value as string);
        },
        t('commons:errors.iban')
    );

    Validator.register(
        'ibanCountry',
        function (value, requirement, attribute) {
            return extractIBAN(value as string).countryCode === requirement;
        },
        t('commons:errors.iban_country')
    );

    Validator.register(
        'bic',
        function (value, requirement, attribute) {
            return !value || isValidBIC(value as string);
        },
        t('commons:errors.bic')
    );

    Validator.register(
        'bicCountry',
        function (value, requirement, attribute) {
            return !value || extractBIC(value as string).countryCode === requirement;
        },
        t('commons:errors.bic_country')
    );

    Validator.register(
        'nomenclatureCountry',
        function (value, requirement, attribute) {
            switch (String(requirement)) {
                case 'AU':
                case 'GB':
                    return null !== validNomenclatureGB.exec(String(value));
                case 'CA':
                case 'US':
                    return null !== validNomenclatureUS.exec(String(value));
                default:
                    return true;
            }
        },
        t('commons:errors.nomenclature_country')
    );

    Validator.register(
        'after_or_equal',
        function (date, params) {
            const val1 = date.toString();
            const val2 = params.split(',')[0];

            if (!isValidDate(val1) || !isValidDate(val2)) {
                return false;
            }

            const inputDate = parseISO(val1);
            const afterDate = parseISO(val2);

            return inputDate.getTime() >= afterDate.getTime();
        },
        t('commons:errors.date')
    );

    Validator.register(
        'before_or_equal',
        function (date, params) {
            const val1 = date.toString();
            const val2 = params.split(',')[0];

            if (!isValidDate(val1) || !isValidDate(val2)) {
                return false;
            }

            const inputDate = parseISO(val1);
            const beforeDate = parseISO(val2);

            return inputDate.getTime() <= beforeDate.getTime();
        },
        t('commons:errors.date')
    );

    Validator.register(
        'exceed_contract_amount',
        function(amount , params):boolean {
            const contract_amount = params.split(',')[0];
            if(contract_amount
                && Number.isInteger(+contract_amount)
                && +contract_amount !== -1
                && Number.isInteger(amount)) {
                return +amount <= +contract_amount;
            } else {
                return true;
            }
        },
        t('commons:errors.MAX_AMOUNT_EXCEEDED')
    )
};

const rulesBeneficiary = {
    beneficiaryBankName: [{ required_if: ['beneficiaryAccountType', AccountType.AUTR] }, 'max:35'],
    beneficiaryTownBankName: [{ required_if: ['beneficiaryAccountType', AccountType.AUTR] }],
    beneficiaryAccountType: ['required'],
    beneficiaryAccountCountryCode: ['required'],
    accountReference: ['required', 'max:35'],
    bankCodeType: [{ required_if: ['beneficiaryAccountType', AccountType.AUTR] }],
    bankCodeReference: [{ required_if: ['beneficiaryAccountType', AccountType.AUTR] }],
    beneficiaryName: ['required', 'string', 'min:3', 'max:70', alphaNumericWithAccentsAndSomeSpecials],
    beneficiaryAlias: ['required', 'string', 'min:3', 'max:70', alphaNumericWithAccentsAndSomeSpecials],

    'beneficiaryAddresses.*.streetName': ['max:70', alphaNumericWithAccentsAndSomeSpecials],
    'beneficiaryAddresses.*.buildingNumber': ['max:30', alphaNumericWithAccentsAndSomeSpecials],
    'beneficiaryAddresses.*.postBox': ['max:30', alphaNumericWithAccentsAndSomeSpecials],
    'beneficiaryAddresses.*.postCode': ['required', 'max:30', alphaNumericWithAccentsAndSomeSpecials],
    'beneficiaryAddresses.*.townName': ['required', 'string', 'max:35', alphaNumericWithAccentsAndSomeSpecials],
    'beneficiaryAddresses.*.countryCode': ['required'],

    beneficiaryStreetGroup: ['max:35'],
    beneficiaryTownGroup: ['max:35']
};

const rulesCharacteristicsDetails = {
    amount: {
        value: 'required',
        currency: 'required'

    },
    endToEndReference: [`max:35`],
    regulatoryReportingReason: [{ required_if: ['regulatoryReportingReasonNeeded', true] } as any],
    executionDate: ['required', `after_or_equal:${formatISO(new Date(), { representation: 'date' })}`, `before_or_equal:${formatISO(maxDate(), { representation: 'date' })}`],
    transferRequestFees: 'required',
    typeChecked: [{ required_if: ['step', 2] }]
};

const rulesStandingOrderCharacteristicsDetails = {
    amount: {
        value: 'required',
        currency: 'required',
    },
    regulatoryReportingReason: [{ required_if: ['regulatoryReportingReasonNeeded', true] } as any],
    transferRequestFees: 'required',
    firstExecutionDate: ['required', `after_or_equal:${formatISO(new Date(), { representation: 'date' })}`]
};

const rulesCollectivePaymentCharacteristicsDetails = {
    executionDate: ['required', `after_or_equal:${formatISO(new Date(), { representation: 'date' })}`],
    reference: ['required', `max:35`, alphaNumericWithSomeSpecials]
};

const rulesIban = (country: string) => ({
    accountReference: ['required', 'iban', `ibanCountry:${country}`]
});

const rulesBicWithoutNomenclature = (country: string) => ({
    bankCodeReference: ['required', 'min:8', 'max:11', 'bic', `bicCountry:${country}`]
});

const rulesBicWithNomenclature = (country: string) => ({
    bankCodeReference: ['required_without:foreignNationalReference', 'min:8', 'max:11', 'bic', `bicCountry:${country}`],
    foreignNationalReference: ['required_without:bankCodeReference', `nomenclatureCountry:${country}`]
});

export const useValidation = () => {
    const { t } = useTranslation(['commons']);
    const [ready, setReady] = useState(false);
    const [validationErrors, setValidationErrors] = useState({} as any);

    if (!ready) {
        setReady(true);
        registerCustomValidators(t);
    }

    const validate = useCallback(
        (data: Object, rules: any) => {
            setValidationErrors({});
            const validator = new Validator(data, rules, Validator.getMessages(Validator.getDefaultLang()));
            if (validator.fails()) {
                setValidationErrors(validator.errors.errors);
            }
            return validator;
        },
        []
    );

    const hasError = useCallback(
        (fieldName: string) => typeof validationErrors[fieldName] !== 'undefined', [validationErrors]
    );

    const getBeneficiaryRules = useCallback(
        (beneficiaryAccountType: AccountType, bankCodeType: BankCodeType | undefined, beneficiaryAccountCountryCode: string | null, isTransferWorkflow = false) => {
            const nomenclatureCountryCodes = ['US', 'CA', 'GB', 'AU'];
            return {
                ...rulesBeneficiary,
                ...isTransferWorkflow && {
                    beneficiaryAlias: ['optional', 'string', 'min:3', 'max:70', alphaNumericWithAccentsAndSomeSpecials]
                },
                ...(beneficiaryAccountType === AccountType.IBAN && { ...rulesIban(beneficiaryAccountCountryCode ?? '') }),
                ...(beneficiaryAccountType === AccountType.AUTR &&
                    bankCodeType === BankCodeType.BIC &&
                    (!beneficiaryAccountCountryCode || !nomenclatureCountryCodes.includes(beneficiaryAccountCountryCode)) && {
                    ...rulesBicWithoutNomenclature(beneficiaryAccountCountryCode ?? '')
                }),
                ...(beneficiaryAccountType === AccountType.AUTR &&
                    bankCodeType === BankCodeType.BIC &&
                    beneficiaryAccountCountryCode &&
                    nomenclatureCountryCodes.includes(beneficiaryAccountCountryCode) && {
                    ...rulesBicWithNomenclature(beneficiaryAccountCountryCode ?? '')
                })
            };
        },
        []
    );

    const getCharacteristicsDetailsRules = useCallback(
        (regulatoryReportingReasonSize: number) => {
            return {
                ...rulesCharacteristicsDetails,
                ...regulatoryReportingReasonSize && {
                    regulatoryReportingReason: [{ required_if: ['regulatoryReportingReasonNeeded', true] } as any, `size:${regulatoryReportingReasonSize}`]
                }
            };
        }, []
    );

    const getStandingOrderCharacteristicsDetailsRules = useCallback(
        (regulatoryReportingReasonSize: number) => {
            return {
                ...rulesStandingOrderCharacteristicsDetails,
                ...regulatoryReportingReasonSize && {
                    regulatoryReportingReason: [{ required_if: ['regulatoryReportingReasonNeeded', true] } as any, `size:${regulatoryReportingReasonSize}`],
                },
            };
        }, []
    );

    const getCollectivePaymentCharacteristicsDetailsRules = useCallback(() =>
        rulesCollectivePaymentCharacteristicsDetails, []
    );


    return {
        validate,
        hasError,
        validationErrors,
        setValidationErrors,
        getBeneficiaryRules,
        getCharacteristicsDetailsRules,
        getStandingOrderCharacteristicsDetailsRules,
        getCollectivePaymentCharacteristicsDetailsRules,
        registerCustomValidator: Validator.register
    };
};
