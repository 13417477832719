import React from 'react';
import {Box, Paper, Stack, styled, Typography} from '@mui/material';

const StyledPaper = styled(Paper)(({theme}) => ({
    width: '100%',
    boxShadow: '0px 3px 6px #00000012 !important',
    borderRadius: '5px',
    display: 'flex',
    [theme.breakpoints.up('md')]: {
        height: '120px',
    },
    [theme.breakpoints.up('xl')]: {
        height: '150px',
    }
}));

export const TypographyH2Styled = styled(Typography)(({theme}) => ({
    fontFamily: 'BNPPSansCondensed',
    fontSize: '1.6rem',
    lineHeight: '1.6rem',
    letterSpacing: '0.1px',
    textTransform: 'uppercase',
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
        fontSize: '1.4rem',
        lineHeight: '1.4rem',
        marginBottom: '10px',
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '1.6rem',
        lineHeight: '1.6rem',
        marginBottom: '20px',
    }
}));
const TypographyStyled = styled(Typography)(({theme}) => ({
    fontFamily: 'BNPPSans',
    fontSize: '1rem',
    fontWeight: '100',
    [theme.breakpoints.up('md')]: {
        fontSize: '0.8rem',
        lineHeight: '1rem',
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '1rem',
        lineHeight: '1rem',
    }
}));

const StackIcon = styled(Stack)(({theme}) => ({
    height: '100%',
    backgroundColor: '#F2FCF3',
    [theme.breakpoints.up('md')]: {
        minWidth: '120px',
    },
    [theme.breakpoints.up('xl')]: {
        minWidth: '150px',
    }
}));

const CarouselItem = ({title, description, icon} : {title: string, description: string, icon: React.FunctionComponentElement<React.SVGProps<SVGSVGElement>>}) => {
    return (
        <StyledPaper>
            <StackIcon>
                {icon}
            </StackIcon>
            <Stack sx={{height: '100%', textAlign: 'left',  paddingLeft: '30px', paddingRight: '30px'}}>
                <Box sx={{position: 'relative', margin: 'auto 0'}}>
                    <TypographyH2Styled>{title}</TypographyH2Styled>
                    <TypographyStyled>{description}</TypographyStyled>
                </Box>
            </Stack>
        </StyledPaper>
    );
};

export default CarouselItem;
