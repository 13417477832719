import React, {CSSProperties, useMemo} from 'react';
import {AppBar, Box, Grid, Stack, styled, Toolbar, Typography, Dialog} from '@mui/material';
import {Outlet} from 'react-router-dom';
import CitylineSvg from '../../../assets/images/commons/city.svg?react';
import MaintenanceSvg from '../../../assets/images/maintenance.svg?react';
import {useAppBarHeight} from '../../../hooks/useAppBarHeight';

import background from '../../../assets/images/login-page.jpg';
import {useTranslation} from 'react-i18next';
import Footer from '../../_commons/components/Footer/Footer';
import Logo from '../../../assets/images/logo/bgl-logo.svg';
import LogoutUserButton from '../components/LogoutUserButton';
import SelectLanguageButton from '../components/SelectLanguageButton';
import Carousel from '../../_commons/components/Carousel/Carousel';
import CarouselItem from '../screens/CarouselItem';
import {DocumentIcon, EspaceIcon, MessageIcon, SecureIcon} from '../../../assets/images/icons/carousel/CarouselIcons';
import {Autoplay, Navigation, Pagination} from 'swiper/modules';
import {useFooterHeight} from '../../../hooks/useFooterHeight';
import {SwiperOptions} from 'swiper/types';
import {useGetAuthentStatusQuery} from "../../../redux/api/customerAuthentApiRtk.ts";

const TypographyStyled = styled(Typography)(({theme}) => ({
    fontFamily: 'BNPPSansCondensed',
    color: '#FFFFFF',
    letterSpacing: '0.03rem',
    textShadow: '0px 2px 10px #00000069',
    [theme.breakpoints.up('md')]: {
        fontSize: '4rem',
        lineHeight: '60px',
        marginTop: '40px',
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '6rem',
        lineHeight: '100px',
        marginTop: '50px',
    },
}));

const WelcomeBox = styled(Box)(({theme}) => ({
    border: '1px solid #FFFFFF',
    borderRadius: '5px',
    color: 'white',
    backdropFilter: 'brightness(70%)',
    [theme.breakpoints.up('md')]: {
        height: '200px',
    },
    [theme.breakpoints.up('xl')]: {
        height: '300px',
    },
}));

const backdropStyle = {
    width: '100%',
    height: '100%',
    background: 'radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.8) 20%, rgba(255,255,255,0.8) 80%, rgba(255,255,255,0) 100%)',
};

const loginClosed: CSSProperties  = {
    width: '100%',
    maxWidth: '350px',
    justifySelf: 'center',
    textAlign: 'center',
    background: 'rgba(255,255,255, 1)',
    padding: '1rem',
    borderRadius: '1rem',
    height: 'fit-content',
}

const AuthLayout = ({title}: { title: string }) => {
    const {t} = useTranslation(['authentication']);
    const appBarHeight = useAppBarHeight();
    const footerHeight = useFooterHeight();

    const carouselOptions: SwiperOptions = {
        spaceBetween: 100,
        autoplay: {delay: 6000, disableOnInteraction: false},
        loop: true,
        speed: 1000,
        modules: [Navigation, Pagination, Autoplay]
    };

    const { data, error}: {data?: any, error?: any} = useGetAuthentStatusQuery(void (0), {})
    const status = useMemo(() => {
        if(error?.data?.errorCode) {
            if(error.data.errorCode.indexOf('Maintenance') !== -1) {
                return {showStatusDialog : true, title : t('errors.maintenance-title'), description : t('errors.maintenance-description') }
            }
            if(error.data.errorCode.indexOf('LuxtrustDown') !== -1) {
                return {showStatusDialog : true, title : t('errors.luxtrust-ko-title'), description :t('errors.luxtrust-ko-description') }
            }
        } else {
            return {showStatusDialog : false, title : '', description : '' }
        }
    }, [error, t])

    return (
        <Grid container>
            <Grid item xs={12} md={5} xl={4} sx={{boxShadow: '15px 5px 20px #00000022', zIndex: 20}}>
                <AppBar position={'sticky'} variant={'elevation'} elevation={1}
                        color={'inherit'} style={{boxShadow: 'none'}}>
                    <Toolbar sx={{justifyContent: 'space-between'}}>
                        <Stack direction={'row'} alignItems={'center'} flex={0.8} pl={'18px'}>
                            <img alt={'logo'} src={Logo} style={{height: '35px'}}/>
                        </Stack>
                        <Stack direction={'row'} alignItems={'center'} flex={0.8} pr={'18px'} spacing={8}
                               justifyContent={'end'}>
                            <SelectLanguageButton/>
                            <LogoutUserButton/>
                        </Stack>
                    </Toolbar>
                </AppBar>
                <Box sx={{position: 'sticky', top: '65px', minHeight: `calc(100vh - ${appBarHeight}px)`}}>
                    <Dialog
                        open = {status?.showStatusDialog ? status.showStatusDialog : false}
                        disablePortal
                        hideBackdrop
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{position: 'absolute'}}
                        PaperProps={{
                            sx: {
                                boxShadow: '#c6374247 0px 4px 12px'
                            }
                        }}
                        slotProps={{backdrop: {style: {position: 'inherit'}}}}
                        style={backdropStyle}>
                            <Box style={loginClosed}>
                                <MaintenanceSvg width={'125px'} />
                                <Typography variant={'h4'} gutterBottom textAlign={'center'}>
                                    {status?.title}
                                </Typography>
                                <Typography variant={'body1'} fontWeight={100} gutterBottom textAlign={'center'}>
                                    {status?.description}
                                </Typography>
                            </Box>
                    </Dialog>
                    <Box sx={{paddingTop: {xs: '5vh', sm: '10vh'}}}>
                        <Typography variant="h1" fontWeight={400} gutterBottom textAlign={'center'}>
                            {title}
                        </Typography>
                    </Box>

                    <Outlet/>

                    <Box sx={{
                        position: 'absolute',
                        zIndex: -1,
                        bottom: 0,
                        width: '100%',
                        overflow: 'hidden',
                        display: {sm: 'none', md: 'block'}
                    }}>
                        <CitylineSvg width={'100%'}
                                     style={{marginBottom: '-10px'}}/>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12} md={7} xl={8} sx={{display: {xs: 'none', md: 'block'}}}>
                <Box sx={{
                    textAlign: 'center',
                    minHeight: `calc(100vh - ${footerHeight}px)`,
                    background: `url(${background})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPositionX: 'right'
                }}>
                    <Stack width={{sm: '500px', xl: '700px'}}
                           sx={{margin: 'auto', height: 'calc(100vh - 57px)', justifyContent: 'center'}}>
                        <WelcomeBox>
                            <TypographyStyled>{t('authentication:welcome.title')}
                                <br/>{t('authentication:welcome.brand')}</TypographyStyled>
                        </WelcomeBox>
                        <Stack sx={{marginTop: {sm: '50px', xl: '100px'}}}>
                            <Carousel options={carouselOptions} className={'carousel-auth'}>
                                <CarouselItem
                                    title={t('authentication:carousel.titleFirst')}
                                    description={t('authentication:carousel.descriptionFirst')}
                                    icon={<EspaceIcon className={'carousel-auth-icon'}/>}
                                />
                                <CarouselItem
                                    title={t('authentication:carousel.titleSecond')}
                                    description={t('authentication:carousel.descriptionSecond')}
                                    icon={<DocumentIcon className={'carousel-auth-icon'}/>}
                                />
                                <CarouselItem
                                    title={t('authentication:carousel.titleThird')}
                                    description={t('authentication:carousel.descriptionThird')}
                                    icon={<MessageIcon className={'carousel-auth-icon'}/>}
                                />
                                <CarouselItem
                                    title={t('authentication:carousel.titleFour')}
                                    description={t('authentication:carousel.descriptionFour')}
                                    icon={<SecureIcon className={'carousel-auth-icon'}/>}
                                />
                            </Carousel>
                        </Stack>
                    </Stack>
                </Box>
                <Footer width={'100%'} marginLeft={'0'} isShowLogo={false}/>
            </Grid>

            <Box sx={{display: {xs: 'block', md: 'none'}, width: '100%'}}>
                <Footer width={'100%'} marginLeft={'0'}/>
            </Box>

        </Grid>

    );
};

export default AuthLayout;
