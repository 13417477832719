import {IdPredicate, StringPredicate} from './CommonTypes';

export type ContractStatus = 'ACTIVE' | 'CLOSED' | 'CREATED' | 'MODIFIED';

export type WebBankingBusinessPermissionTypeEnum =
    'BENEFICIARY_MANAGEMENT'
    | 'CARD_HOLDER'
    | 'CARD_MANAGEMENT'
    | 'CONTRACT_MANAGEMENT'
    | 'CREDIT_MANAGEMENT'
    | 'DECISION_MAKER'
    | 'DOMICILIATION_CONSULTATION'
    | 'ELECTRONIC_DOCUMENT_CONSULTATION'
    | 'SALARY_PAYMENT'
    | 'VIEW_ACCOUNT_CONSULTATION'
    | 'AT_LEAST_ONE_STANDARD_PAYMENT_RULE'
    | 'AT_LEAST_ONE_SALARY_PAYMENT_RULE';


export enum WebBankingBusinessRoles {
    PTDC = 'PTDC',
    CONS = 'CONS',
    CDMA = 'CDMA',
    CAMA = 'CAMA',
    BEMA = 'BEMA',
    SDMA = 'SDMA',
    SLMA = 'SLMA',
    EDMA = 'EDMA',
    DECI = 'DECI',
    SADM = 'SADM'
}

export type SubjectSerialNumber = {
    code: string;
};

export type WebBankingBusinessSelfAdmin = {
    enabled: boolean;
    fourEyesValidation: boolean;
};

export type UpdateCodeEnum =
    'NOT_ENOUGH_ADMIN' |
    'NOT_ENOUGH_USER_A' |
    'NOT_ENOUGH_USER_B' |
    'NOT_ENOUGH_USER_C' |
    'NOT_ENOUGH_USER_A_WITH_SALARY_PAYMENT_PERMISSION' |
    'INCONSISTENT_AMOUNT_FOR_SIGNING_RULE' |
    'NOT_ENOUGH_USER_WITH_SALARY_PAYMENT_PERMISSION' |
    'NOT_ENOUGH_USER_A_WITH_SALARY_PAYMENT_PERMISSION' |
    'NOT_ENOUGH_USER_B_WITH_SALARY_PAYMENT_PERMISSION' |
    'NOT_ENOUGH_USER_C_WITH_SALARY_PAYMENT_PERMISSION' |
    'NOT_ENOUGH_USER_WITH_ENCODING_PAYMENT_FLAG_FOR_SALARY' |
    'USER_WITH_SALARY_PAYMENT_PERMISSION_WHEN_NOT_PROVIDED' |
    'NOT_ENOUGH_USER_WITH_ENCODING_PAYMENT_FLAG' |
    'USER_WITH_TRANSACTION_PROFILE_A_NOT_EXISTENT' |
    'USER_WITH_TRANSACTION_PROFILE_B_NOT_EXISTENT' |
    'USER_WITH_TRANSACTION_PROFILE_C_NOT_EXISTENT';

export type WebBankingBusinessContract = {
    id: string;
    displayName: string;
    status: ContractStatus;
    contactParty: {
        label: string;
    }
    partyProfiles: WebBankingBusinessPartyProfile[];
    selfAdmin: WebBankingBusinessSelfAdmin;
};

export type ContractUpdatedResult = {
    updates: ParentUpdatedData[];
    updaterId: string;
    updaterName: string;
    updateNumber: number;
    warnings: UpdateCodeEnum[];
    errors: UpdateCodeEnum[];
    lastUpdateTime: string;
};

export type TransactionProfileEnum = 'CONSULTATION' | 'PROFILE_A' | 'PROFILE_B' | 'PROFILE_C' | 'NO_PROFILE';

export type WebBankingBusinessPartyProfile = {
    id: string;
    ssn: SubjectSerialNumber[];
    permissions?: WebBankingBusinessProfilePermission[];
    accessBlocked: boolean;
    paymentEncodingAllowed: boolean;
    transactionProfileEnum: TransactionProfileEnum;
    partyRoleInCorporationRef: string;
    party: {
        label: string;
        smid: string;
    };
};

export type WebBankingBusinessProfilePermission = {
    id?: string;
    typeRef?: string;
    typeEnum?: WebBankingBusinessPermissionTypeEnum;
    label: string;
    code: string;
};

export type GetPartyProfileRequest = {
    partyId: string;
    signatureRequest?: { challengeId: string, answer: string }
};

export type Contact = {
    thirdPartyId: string;
    lastName: string;
    firstName: string;
};

export type UpdateContractLabelRequest = {
    newLabel: string;
};

export type Party = {
    id: string;
    label: string;
};

export type AuditLog = {
    action: 'CREA' | 'MODI' | 'VALI' | 'CLOT' | 'ARCH' | 'CANC' | 'MIRP' | 'SYNC';
    label: string;
    timestamp: string;
    party?: Party;
};

export type AuditLogResponse = {
    items: AuditLog[];
    totalNumberOfPages: number;
    totalNumberOfItems: number;
    hasNextPage: boolean;
    pageNumber: number;
    pageSize: number;
};

export type Paging = {
    pageNumber: number;
    pageSize: number;
};

export type AuditLogOrder = {
    orderBy: 'TIMESTAMP' | 'ACTION' | 'UNKNOWN_ENUM_VALUE';
    ordering: 'ASCENDING' | 'DESCENDING';
};

export type AuditLogRequest = {
    filter?: AuditLogFilter;
    paging: Paging;
    order: AuditLogOrder;
};

export type AuditLogFilter = {
    action: StringPredicate;
    employeeId: IdPredicate;
    label: StringPredicate;
    partyId: IdPredicate;
};

export type UpdatePartyProfileRequest = {
    id: string;
    paymentEncoder: boolean;
    transactionProfile: TransactionProfileEnum;
    activatedPermissions: WebBankingBusinessRoles[];
};

export type UpdateContactRequest = {
    newContactThirdPartyId?: string;
    newContactFirstName?: string;
    newContactLastName?: string;
};

export type ParentUpdatedData = {
    infoUser: InfoUser;
    children: ChildrenUpdatedData[];
    type: string;
    oldValue: string;
    newValue: string;
};

export type ChildrenUpdatedData = {
    infoUser: InfoUser;
    type: string;
    oldValue: string | null;
    newValue: string | null;
    label?: string;
};

export type InfoUser = {
    id: string;
    thirdPartyId: number;
    firstName: string;
    lastName: string;
    ssns: string[];
};

export type UpdatePaymentConstraintRequest = {
    contractReference: string;
    webBankingBusinessPaymentConstraint: WebBankingBusinessPaymentConstraint[];
}

export type WebBankingBusinessPaymentConstraint = {
    paymentConstraint?: PaymentConstraint | null;
    salaryPaymentConstraint?: PaymentConstraint | null;
}

export type PaymentConstraint = {
    isAllowed: boolean;
    maxAmount: MaxAmount;
    signatureProfile: SignatureProfile[];
}

export type SignatureProfile = {
    requiredTransactionProfiles: string[];
    maxAmount: MaxAmount;
}

export type MaxAmount = {
    nb: number | null;
    currencyRef: string;
}

export type PaymentConstraintsQuery = {
    contractReference: string;
    status?: string;
}

export type DisplayStyle = 'ALL' | 'ADMIN' | 'NOADMIN' | 'ACTIVE' | 'SUSPENDED';
