import '../cookies.scss';
import CookieSvg from '../../../../../assets/images/cookie.svg';
import {useLanguage} from '../../../../../hooks/useLanguage';
import {useTranslation} from 'react-i18next';
import {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {useAnalytics} from '../../../../../hooks/useAnalytics';

declare global {
    interface Window {
        CookieConsent: any;
    }
}

const useCookieConsent = () => {
    const [language] = useLanguage();
    const {t} = useTranslation(['commons']);
    const [firstRun, setFirstRun] = useState(true);
    const hostNameParts = window.location.hostname.split('.');
    const shortDomain = `.${hostNameParts[hostNameParts.length - 2]}'.'${hostNameParts[hostNameParts.length - 1]}`;
    const {pushAnalytics} = useAnalytics();

    const _windowCookieConsentInit = useCallback(() => {

        if(!window || !window.CookieConsent){
            return;
        }

        const modal = document?.getElementById('cconsent-modal');
        const bar = document?.getElementById('cconsent-bar');
        if (modal) {
            modal.outerHTML = '';
        }

        if (bar) {
            bar.outerHTML = '';
        }

        window.CookieConsent.init({

            cookieVersion: 1,
            // More link URL on bar
            modalMainTextMoreLink: false,
            // How long to wait until bar comes up
            barTimeout: 1000,
            showRejectAllButton: true,
            // Look and feel
            theme: {
                barColor: '#ffffff',
                barTextColor: '#707070',
                barMainButtonColor: '#01965e',
                barMainButtonTextColor: '#ffffff',
                modalMainButtonColor: '#01965e',
                modalMainButtonTextColor: '#ffffff'
            },
            language: {
                // Current language
                current: language,
                locale: {
                    [`${language}`]: {
                        barMainText: t('commons:modules.webpro.cookies-consent.barMainText'),
                        barLinkSetting: t('commons:modules.webpro.cookies-consent.manage-cookies'),
                        barBtnAcceptAll: t('commons:modules.webpro.cookies-consent.accept-cookies'),
                        barBtnRejectAll: t('commons:modules.webpro.cookies-consent.decline-cookies'),
                        modalMainTitle: t('commons:modules.webpro.cookies-consent.modalMainTitle'),
                        modalMainText: t('commons:modules.webpro.cookies-consent.modalMainText'),
                        modalBtnSave: t('commons:modules.webpro.cookies-consent.modalBtnSave'),
                        modalBtnClose: t('commons:modules.webpro.cookies-consent.modalBtnClose'),
                        closeAriaLabel: t('commons:modules.webpro.cookies-consent.modalBtnClose'),
                        modalAffectedSolutions: t('commons:modules.webpro.cookies-consent.modalAffectedSolutions'),
                        on: t('commons:modules.webpro.cookies-consent.on'),
                        off: t('commons:modules.webpro.cookies-consent.off')
                    }
                }
            },
            // List all the categories you want to display
            categories: {
                // Unique name
                // This probably will be the default category
                necessary: {
                    // The cookies here are necessary and category cant be turned off.
                    // Wanted config value  will be ignored.
                    needed: true,
                    // The cookies in this category will be let trough.
                    // This probably should be false if not necessary category
                    wanted: true,
                    // If the checkbox is on or off at first run.
                    checked: true,
                    // Language settings for categories
                    language: {
                        locale: {
                            [`${language}`]: {
                                name: t('commons:modules.webpro.cookies-consent.mandatory.name'),
                                description: t('commons:modules.webpro.cookies-consent.mandatory.description')
                            }
                        }
                    }
                },
                analytics: {
                    // The cookies here are necessary and category cant be turned off.
                    // Wanted config value  will be ignored.
                    needed: true,
                    // The cookies in this category will be let trough.
                    // This probably should be false if not necessary category
                    wanted: true,
                    // If the checkbox is on or off at first run.
                    checked: true,
                    // Language settings for categories
                    language: {
                        locale: {
                            [`${language}`]: {
                                name: t('commons:modules.webpro.cookies-consent.analytics.name'),
                                description: t('commons:modules.webpro.cookies-consent.analytics.description')
                            }
                        }
                    }
                },
                comfort: {
                    // The cookies here are necessary and category cant be turned off.
                    // Wanted config value  will be ignored.
                    needed: false,
                    // The cookies in this category will be let trough.
                    // This probably should be false if not necessary category
                    wanted: false,
                    // If the checkbox is on or off at first run.
                    checked: true,
                    // Language settings for categories
                    language: {
                        locale: {
                            [`${language}`]: {
                                name: t('commons:modules.webpro.cookies-consent.comfort.name'),
                                description: t('commons:modules.webpro.cookies-consent.comfort.description')
                            }
                        }
                    }
                },
                offers: {
                    // The cookies here are necessary and category cant be turned off.
                    // Wanted config value  will be ignored.
                    needed: false,
                    // The cookies in this category will be let trough.
                    // This probably should be false if not necessary category
                    wanted: false,
                    // If the checkbox is on or off at first run.
                    checked: false,
                    // Language settings for categories
                    language: {
                        locale: {
                            [`${language}`]: {
                                name: t('commons:modules.webpro.cookies-consent.offers.name'),
                                description: t('commons:modules.webpro.cookies-consent.offers.description')
                            }
                        }
                    }
                }
            },
            // List actual services here
            services: {
                analyticsAdobe: {
                    category: 'analytics',
                    type: 'dynamic-script',
                    search: 'AppMeasurement.min',
                    cookies: [
                        {
                            // Regex matching cookie name.
                            name: /^s_.*/,
                            domain: shortDomain
                        }
                    ],
                    language: {
                        locale: {
                            [`${language}`]: {
                                name: t('commons:modules.webpro.cookies-consent.adobe-analytics')
                            }
                        }
                    }
                },
                luxtrust: {
                    category: 'comfort',
                    language: {
                        locale: {
                            [`${language}`]: {
                                name: t('commons:modules.webpro.cookies-consent.luxtrust-pref')
                            }
                        }
                    }
                }
            }
        });

        if (window.document) {
            document.querySelector('.ccb__left')?.insertAdjacentHTML('beforebegin', `<object type="image/svg+xml" data="${CookieSvg}"  class="cookie-logo"/>`);
            const title = `${t('commons:modules.webpro.cookies-consent.mainText2_Title')}`;
            const more = `${t('commons:modules.webpro.cookies-consent.mainText2_1')} <a href='${t('commons:modules.webpro.cookies-consent.mainLink')}' target="_blank">${t('commons:modules.webpro.cookies-consent.mainTextLink')}</a> ${t('commons:modules.webpro.cookies-consent.mainText2_2')}.`;
            document.querySelector('.ccb__left .cc-text')?.insertAdjacentHTML('beforebegin', `<h3>${title}</h3>`);
            document.querySelector('.ccb__left .cc-text')?.insertAdjacentHTML('afterend', `<div>${more}</div>`);
            document.querySelector('.ccm__content__heading > p')?.insertAdjacentHTML('beforeend', `<div><br/>${more}</div>`);
        }
        
    }, [language, shortDomain, t]);

    useEffect(() => {
        _windowCookieConsentInit();
    }, [language, _windowCookieConsentInit]);

    useLayoutEffect(() => {
        const init = () => {
            if (!window.CookieConsent?.config) {
                _windowCookieConsentInit();
                const consentGive = document.querySelectorAll('.consent-give');
                const consentDecline = document.querySelectorAll('.consent-decline');
                const consentSubmit = document.getElementById('ccm__footer__consent-modal-submit');

                if (consentGive && consentGive.length > 0) {
                    consentGive[1].addEventListener('click', function handleClick(event) {
                        pushAnalytics({rule: 'accept_cookies_tracking_rule', actionName: 'accept_cookies', buttonName: 'accept_cookies_button', pageName: 'cookie banner consent  first screen', cookieConsent: 'yes', sequenceName: 'cookie banner consent'});
                    });
                }
                if (consentDecline && consentDecline.length > 0) {
                    consentDecline[1].addEventListener('click', function handleClick(event) {
                        pushAnalytics({rule: 'decline_cookies_tracking_rule', actionName: 'decline_cookies', buttonName: 'decline_cookies_button', pageName: 'cookie banner consent  first screen', cookieConsent: 'no', sequenceName: 'cookie banner consent'});
                    });
                }
                if (consentSubmit) {
                    consentSubmit.addEventListener('click', function handleClick(event) {
                        pushAnalytics({rule: 'confirm_cookies_choice_tracking_rule', actionName: 'validate_cookies_choice', buttonName: 'validate_cookies_choice_button', pageName: 'cookie banner consent  second screen', cookieConsent: 'no', sequenceName: 'cookie banner consent', stepName: 'step2_validate_cookies_choice'});
                    });
                }
            }
        };
        if (firstRun) {
            setFirstRun(false);
            init();
        }
    }, [firstRun, language, t, _windowCookieConsentInit, pushAnalytics]);
};


export default useCookieConsent;

